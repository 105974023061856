import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faArrowUp,
  faClock,
  faCircleCheck,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { faXing } from "@fortawesome/free-brands-svg-icons";
import bannerVideo from "../assets/images/banner-video.mp4";
import logo from "../assets/images/The Lux Dubai text n fonts UI-UX-32.png";
import sideImage from "../assets/images/ssir-02.webp";
import startingPrice from "../assets/images/The Lux Dubai text n fonts UI-UX-30.webp";
import paymentPlan from "../assets/images/The Lux Dubai text n fonts UI-UX-31.webp";
import forthImage from "../assets/images/The Lux Dubai text n fonts UI-UX-07.webp";
import buyersGuide1 from "../assets/images/The Lux Dubai text n fonts UI-UX-09.webp";
import buyersGuide2 from "../assets/images/The Lux Dubai text n fonts UI-UX-08.webp";
import buyersGuide3 from "../assets/images/The Lux Dubai text n fonts UI-UX-10.webp";
import buyersGuide4 from "../assets/images/The Lux Dubai text n fonts UI-UX-11.webp";
import Luxary18 from "../assets/images/The Lux Dubai text n fonts UI-UX-18.png";
import Luxary19 from "../assets/images/The Lux Dubai text n fonts UI-UX-19.png";
import Luxary20 from "../assets/images/The Lux Dubai text n fonts UI-UX-20.png";
import Luxary21 from "../assets/images/The Lux Dubai text n fonts UI-UX-21.png";
import Luxary22 from "../assets/images/The Lux Dubai text n fonts UI-UX-22.png";
import Luxary23 from "../assets/images/The Lux Dubai text n fonts UI-UX-23.png";
import borchue from "../assets/images/ssir-02.webp";
import quiz from "../assets/images/The Lux Dubai text n fonts UI-UX-17.webp";
import bell from "../assets/images/The Lux Dubai text n fonts UI-UX-15.webp";
import thumb from "../assets/images/The Lux Dubai text n fonts UI-UX-16.webp";
import Footer from "../components/Footer";
import Crousel from "../components/crousel";
import ImageCrousel from "../components/imageCrousel";
import SearchFilter from "../components/filter";
import { Link } from "react-router-dom";

function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleModal2 = () => {
    setShowModal2(!showModal2);
  };
  const toggleModal3 = () => {
    setShowModal3(!showModal3);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (showModal) {
      // Disable scrolling when the modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling when the modal is closed
      document.body.style.overflow = "auto";
    }

    // Cleanup function to re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="slick-arrow custom-prev" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="slick-arrow custom-next" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  };

  return (
    <>
      <div className={`containerr ${isOpen ? "sidebar-open" : ""}`}>
        {/* first section */}
        <div className="first-section">
          <video autoPlay loop muted className="bg-video">
            <source src={bannerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="sideMenue">
            <div className="sideMenuBar">
              <FontAwesomeIcon
                className="humb-icon"
                icon={faBarsStaggered}
                onClick={toggleSidebar}
              />
            </div>
            <div className="logo">
              <img src={logo} />
            </div>
            <div></div>
          </div>
          <div className="bannercontent">
            <div className="row-1">
              <p>NEW LAUNCH</p>
            </div>
            <div className="row-2">
              <p>An Ultra-Luxury Landmark In Palm Jumeirah</p>
            </div>
            <div className="row-3">
              <p>
                This Luxary Residence In Palm Jumeirah Will Comprise A Range Of
                Beautifully Appointed Home Including A Sellect Number Of
                Penthouse, As Well As A Range Of World-class Amenities,
                Carefully Designed To Give Every Resident The Opportunity To
                Enjoy An Eligant, Productive And Actve Lifestyle
              </p>
            </div>
            <div className="row-4">
              <div className="Start-price">
                <img src={startingPrice} />
                <p className="AED-content">AED 11,000,000</p>
                <p>STARTING PRICE</p>
              </div>

              <div className="End-price">
                <img src={paymentPlan} />
                <p className="END-content">Easy 60/40</p>
                <p>PAYMENT PLAN</p>
              </div>
            </div>
            <div className="row-5" style={{ marginRight: "0px" }}>
              <button onClick={toggleModal3}>Leave a Request</button>
            </div>
          </div>
          <div className="search-filter">
            <SearchFilter />
          </div>
          {showModal3 && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModal3}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* side-bar-button section */}

        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="sidebar-content">
            <div className="sideMenuBar">
              <FontAwesomeIcon
                className="humb-icon-cross"
                icon={faXing}
                onClick={toggleSidebar}
              />
            </div>

            <ul>
              <li className="fake-menu">Home</li>
              <li className="fake-menu">Contact</li>
              <li className="fake-menu">Communities</li>
              <li className="fake-menu">Services</li>
              <li className="fake-menu">New Projects</li>
              <li className="fake-menu">Contact us</li>
              <li className="fake-menu">About us</li>
            </ul>
          </div>
        </div>
        {/* second section */}
        <div className="second-section">
          <div class="wrapper-sec2">
            <h2 className="sec-2-title">LIFESTYLE</h2>

            <ul class="nav nav-tabs">
              <li className="active">
                <a data-toggle="tab" href="#home">
                  Penthouse
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#menu1">
                  Appartments
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#menu2">
                  Twonhouse
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#menu3">
                  Villas
                </a>
              </li>
            </ul>

            <div class="tab-content">
              <div id="home" class="tab-pane fade in active">
                <Link to="/detailPage"><div className="cardWrap">
                  <Crousel />
                </div></Link>
              </div>

              <div id="menu1" class="tab-pane fade">
                 <Link to="/detailPage"><div className="cardWrap">
                  <Crousel />
                </div></Link>
              </div>
              <div id="menu2" class="tab-pane fade">
                 <Link to="/detailPage"><div className="cardWrap">
                  <Crousel />
                </div></Link>
              </div>
              <div id="menu3" class="tab-pane fade">
                <Link to="/detailPage"><div className="cardWrap">
                  <Crousel />
                </div></Link>
              </div>
            </div>
          </div>
        </div>
        {/* Third section */}

        <div className="third-section">
          <div className="imagecard-container">
            <h1>LIFE STYLE</h1>
            <p>
              Wide range option for any life style. Make your choice with us
            </p>
            <div className="img-card-wrapper">
              <ImageCrousel />
            </div>
          </div>
        </div>
        {/* fourth section */}
        <div className="fourth-section">
          <div className="fourth-container">
            <div className="fourth-content">
              <img src={logo} className="fourth-sec-logo" />
              <h1>DOWNLOAD THE FULL BROCHURE OF ALL DUBAI PROPERTIES</h1>
              <p>
                We collect 140+ of the best new properties in Dubai for living
                and invesment.Specify your contacts and download the catalog of
                appartments and suites in one click.
              </p>
              <button onClick={toggleModal}>
                DOWNLOAD CATALOG{" "}
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="fourth-button-arrow"
                />
              </button>
            </div>
            <div className="fourth-image">
              <img src={forthImage} className="forth-section-image" />
            </div>
          </div>
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModal}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* fivth section */}
        <div className="fivth-section">
          <h1 className="fivth-sec-title">BUYERS GUIDE FOR THE UAE</h1>
          <div className="fivth-container">
            <div className="fivth-row1">
              <div className="fivth-sec-box-1">
                <img src={buyersGuide1} className="fivth-sec-img1" />
                <div className="content-box1">
                  <h1>Buyers Guide</h1>
                  <p>
                    We collect 140+ of the best new properties in Dubai for
                    living and invesment.Specify your contacts and download the
                    catalog of appartments and suites in one click.
                  </p>
                  <button className="fivth-sec-button" onClick={toggleModal2}>
                    DOWNLOAD PDF{" "}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className="fivth-button-arrow"
                    />
                  </button>
                </div>
              </div>
              <div className="fivth-sec-box-2">
                <img src={buyersGuide2} className="fivth-sec-img1" />
                <div className="content-box2">
                  <h1>Buyers Guide</h1>
                  <p>
                    We collect 140+ of the best new properties in Dubai for
                    living and invesment.Specify your contacts and download the
                    catalog of appartments and suites in one click.
                  </p>
                  <button className="fivth-sec-button" onClick={toggleModal2}>
                    DOWNLOAD PDF{" "}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className="fivth-button-arrow"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="fivth-row2">
              <div className="fivth-sec-box-1">
                <img src={buyersGuide3} className="fivth-sec-img1" />
                <div className="content-box1">
                  <h1>Buyers Guide</h1>
                  <p>
                    We collect 140+ of the best new properties in Dubai for
                    living and invesment.Specify your contacts and download the
                    catalog of appartments and suites in one click.
                  </p>
                  <button className="fivth-sec-button" onClick={toggleModal2}>
                    DOWNLOAD PDF{" "}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className="fivth-button-arrow"
                    />
                  </button>
                </div>
              </div>
              <div className="fivth-sec-box-2">
                <img src={buyersGuide4} className="fivth-sec-img1" />
                <div className="content-box2">
                  <h1>Buyers Guide</h1>
                  <p>
                    We collect 140+ of the best new properties in Dubai for
                    living and invesment.Specify your contacts and download the
                    catalog of appartments and suites in one click.
                  </p>
                  <button className="fivth-sec-button" onClick={toggleModal2}>
                    DOWNLOAD PDF{" "}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className="fivth-button-arrow"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showModal2 && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModal2}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* sixth section */}
        <div className="sixth-section">
          <div className="sixth-container">
            <FontAwesomeIcon icon={faCircleCheck} />
            <div className="sixth-left-image">
              <img src={quiz} className="sixth-quiz-image" />
            </div>
            <div className="sixth-content">
              <h3> Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                egestas hendrerit diam, eget posuere massa lacinia pharetra.
                Praesent in rhoncus nibh, vel gravida elit. Fusce faucibus nec
                orci vel aliquet. Praesent accumsan, diam sit amet mollis
                volutpat, lectus turpis ornare orci, ac fringilla elit turpis
                vitae ex. Phasellus nec tortor augue. Suspendisse a consectetur
                dolor
              </p>
              <br></br>
              <p>
                {" "}
                Fusce faucibus nec orci vel aliquet. Praesent accumsan, diam sit
                amet mollis volutpat, lectus turpis ornare orci, ac fringilla
                elit turpis vitae ex. Phasellus nec tortor augue. Suspendisse a
                consectetur dolor. Morbi sit amet eros non mi efficitur
                tincidunt vel eu orci. Cras a lobortis ligula. Aliquam vel
                dignissim libero. Maecenas quis massa sollicitudin, placerat
                neque ut, mollis magna. Nulla tellus risus, dictum eu orci
                volutpat, varius dapibus justo.
              </p>
              <button>
                TAKE A QUIZ
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="fourth-button-arrow"
                />
              </button>{" "}
              <span>
                {" "}
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ color: "#c38b74" }}
                />{" "}
                IT TAKES LESS THAN 2 MINUET
              </span>
            </div>
          </div>
        </div>

        {/* seventh section */}
        <div className="seventh-section">
          <h1>EXPLORE THE MAP</h1>
          {/* <img src={map} className='map'/> */}
          <iframe
            style={{ width: "100%", height: "80vh" }}
            src="https://b2b.behomes.tech/frame/map/1702729359763x367954987423891460?lang=EN"
          ></iframe>
        </div>
        {/* 8th section */}
        <div className="eight-section">
          <h1>LUXARY REAL AREAS IN DUBAI</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {/* First column */}
                <div className="image-container text-white p-3 img-1">
                  <img src={Luxary18} className="luxary-18" />
                  <h1 className="innerImageText img-1">DOWN TOWN DUBAI</h1>
                </div>
              </div>
              <div className="col-md-3">
                {/* Second column */}
                <div className="image-container text-white p-3 img-2">
                  <img src={Luxary19} className="luxary-19" />
                  <h1 className="innerImageText img-2">DUBAI HILLS ESTATE</h1>
                </div>
              </div>
              <div className="col-md-3">
                {/* Third column with wider width */}
                <div className="image-container text-white p-3 img-3">
                  <img src={Luxary20} className="luxary-20" />
                  <h1 className="innerImageText img-3">EMAAR BEACH FRONT</h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                {/* First column */}
                <div className="image-container text-white p-3 img-4">
                  <img src={Luxary22} className="luxary-21" />
                  <h1 className="innerImageText img-4">DUBAI HILLS ESTATE</h1>
                </div>
              </div>
              <div className="col-md-3">
                {/* Second column */}
                <div className="image-container text-white p-3 img-5">
                  <img src={Luxary23} className="luxary-22" />
                  <h1 className="innerImageText img-5">PALM JUMEIRAH</h1>
                </div>
              </div>
              <div className="col-md-6">
                {/* Third column with wider width */}
                <div className="image-container text-white p-3 img-6">
                  <img src={Luxary21} className="luxary-23" />
                  <h1 className="innerImageText img-6">EMAAR BEACH FRONT</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 9th section */}
        <div className="ninth-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="bg-dark ninth-container text-white p-3">
                  <div className="ninth-content">
                    <div className="stay-tuned-content">
                      <div className="ninth-icon">
                        <img src={bell} className="ninth-bell-icon" />
                      </div>
                      <div className="stay-tuned-text">
                        <h3>Lorem ipsum dolor sit amet, consectetu</h3>
                        <p>
                          Lorem ipsum dolor sit amet, g elit Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit
                        </p>
                        <img src={thumb} className="ninth-thumb" />
                      </div>
                    </div>
                    <div className="ninth-form">
                      <div>
                        <input
                          type="text"
                          placeholder="ENTER NAME"
                          className="ninth-input-txt"
                        />
                      </div>
                      <div>
                        <input
                          type="email"
                          placeholder="ENTER EMAIL"
                          className="ninth-input-email"
                        />
                      </div>
                    </div>
                    <div className="sub-now-button">
                      <button>
                        SUBSCRIBE NOW{" "}
                        <FontAwesomeIcon
                          icon={faBell}
                          className="sub-bell-icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer section */}
        <div>
          <Footer active="value" />
        </div>
      </div>
    </>
  );
}

export default Index;
