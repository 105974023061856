import React from 'react'
import "./footer.css"
import { faArrowUp,faCircleUp } from '@fortawesome/free-solid-svg-icons';
import { faFacebook,faTwitter,faInstagram,faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Assuming you want to use the Facebook brand icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../assets/images/The Lux Dubai text n fonts UI-UX-32.png"
import LUXlogo from "../assets/images/The Lux Dubai text n fonts UI-UX-25.webp"
import whatsapp from "../assets/images/whatsapp-ar21.svg"
import sirr from "../assets/images/sirr.png"
import { Link } from 'react-router-dom';

function Footer( prop ) {
    console.log(prop.active , "---------------");
    const handleScrollTop = () => {
        window.scrollTo({
      top: 0,
      behavior: 'smooth' // Add smooth scrolling behavior
    });    }
  return (
    <>
          <div className='section-footer'>
              <div className='footer-main'>
                  {prop.active == "value" ?
                      (<div className='footer-form-section'>
                      <div className='footer-form-content'>
                          <h1><span className='register-span'>REGISTER</span> YOUR INTEREST</h1>
                          <p>Fill form below and our agent will contact you shortly</p>
                      </div>
                      <div className='footer-form'>
                          <form>
                              
                          <input type='text' placeholder='FULL NAME' className='ninth-input-txt'/>
                          <input type='email' placeholder='EMAIL ADDRESS' className='ninth-input-email'/>
                          <input type='number' placeholder='PHONE NUMBER' className='ninth-input-num'/>
                              <button className='footer-submit-btn'><FontAwesomeIcon icon={faArrowUp} className='footer-button-arrow'/>SUBMIT</button>
                                  <p className='footer-whatsapp-text' style={{
                            marginTop:"20px"
                        }}>Or contact us right now via <Link to="/"><FontAwesomeIcon icon={faWhatsapp} style={{
                            color:"#3bc53b", fontSize:"35px"
                                              }} /><span style={{
                            color:"#3bc53b", fontWeight:"800",marginLeft:"3px",fontSize:"23px"
                        }}>WhatsApp </span></Link></p>
                              </form>

                      </div>
                      </div>)
                      :
                      (<div class="container">
                              <div class="row">
                                  <div className='det-footer-image-wraper'>
                                        <div class="col-md-6" style={{
                                                                    width: "100%",
                                                    margin:"auto"
                                            }}>
                                            <img src={sirr} alt="Image" class="img-fluid" style={{
                                                width:"100%"
                                            }}/>
                                      </div>
                                      </div>
        <div class="col-md-6">

            <div class="det-footer-form-section">
                <div class="det-footer-form-content">
                </div>
                <div class="det-footer-form">
                    <form>
                        <input type="text" placeholder="FULL NAME" class="det-footer-input-txt"/><br/>
                        <input type="email" placeholder="EMAIL ADDRESS" class="det-footer-input-email"/><br/>
                        <input type="number" placeholder="PHONE NUMBER" class="det-footer-input-num"/><br/>
                        <button class="footer-submit-btn">
                        SUBMIT
                        </button>
                                              <p className='footer-whatsapp-text'>Or contact us right now via <Link to="/"><FontAwesomeIcon icon={faWhatsapp} style={{
                            color:"#3bc53b", fontSize:"35px"
                                              }} /><span style={{
                            color:"#3bc53b", fontWeight:"800",marginLeft:"3px",fontSize:"23px"
                        }}>WhatsApp </span></Link></p>

                    </form>
                </div>
            </div>
        </div>
    </div>
                    </div>
                      )
                  }
                  <div className='basic-footer-section'>
                      <div className='basic-footer-top'>
                          <div className='footer-logo'>
                              <img src={logo} className='footer-logo-img'/>
                          </div>
                          <div className='footer-icons'>
                              <p className='footer-icon-text'>Follow us on:</p> 
                              {/* <img src={facebook} className='fb'/> */}
                              <FontAwesomeIcon icon={faFacebook} className='fb' />
                              <FontAwesomeIcon icon={faTwitter} className='twiter'/>
                              {/* <img src={twiter} className='twiter' /> */}
                              <FontAwesomeIcon icon={faInstagram} className='insta' />
                              {/* <img src={insta} className='insta'/> */}
                              {/* <img src={yt} className='yt' /> */}
                              <FontAwesomeIcon icon={faYoutube} className='yt'/>
                          </div>
                          
                      </div>
                      <hr/>
                       <div className='basic-footer-mid'>
                          <div className='footerMid-row-1'>
                              <h1>Projects</h1>
                              <ul>
                                  <li>DAMMAC LAGOON</li>
                                  <li>Viewz By DANUBES</li>
                                  <li>SOBAH ONE</li>
                                  <li>VIEW ALL <FontAwesomeIcon icon={faArrowUp} className='footer-button-arrow'/></li>
                              </ul>
                          </div>
                          <div className='footerMid-row-2'>
                              <h1>Developers</h1>
                                <ul>
                                  <li>DAMMAC PROPERTIES</li>
                                  <li>DUBAI PROPERTIES</li>
                                  <li>ELLINGTON PROPERTIES</li>
                                  <li>EMAAR PROPERTIES</li>
                                  <li>SOBAH REALITY</li>
                                  <li>VIEW ALL <FontAwesomeIcon icon={faArrowUp} className='footer-button-arrow'/></li>
                              </ul>

                          </div>
                          <div className='footerMid-row-3'>
                              <h1>Discover</h1>
                                <ul>
                                  <li>MIAMI</li>
                                  <li>LOS ANGELES</li>
                                  <li>NEW YOURK</li>
                                  <li>CHICAGO</li>
                              </ul>
 
                          </div>
                          <div className='footerMid-row-4'>
                          <h1>Discover</h1>
                                <ul>
                                  <li>TOTAL FREE CUSTOMER CARE</li>
                                  <li>+(971) 123 050 945</li>
                                  <li>LUX@PROPERTY.COM</li>
                              </ul>
                              <h4 className='footer-row4-h4'>keep yourself upto date.</h4>
                              <button className='footer-row4-btn'>YOUR MAIL <span className='footer-span-for-padding'> </span> SUBSCRIBE</button>
                          </div>
                      </div>
                      <div className='basic-footer-midLower'>
                          <img src={LUXlogo} className='LuxDubai-footer-img'/>
                      </div>
                      <FontAwesomeIcon icon={faCircleUp} className='circle-up' onClick={handleScrollTop}/>
                        <hr className='hr-bottom'/>
                      <div className='basic-footer-lower' >
                        <p>Lux All Rights reserved</p>
                          <p>PRIVACY | TERMS | SITEMAPS</p>
                      </div>
                  </div>
              </div>
          </div>  
    </>
  )
}

export default Footer