import React, { useState } from "react";
import Image from "../../assets/images/lux2.jpg";
import layout from "../../assets/images/layout.jpg";
import logo from "../../assets/images/The Lux Dubai text n fonts UI-UX-32.png";
import Luxary18 from "../../assets/images/The Lux Dubai text n fonts UI-UX-18.png";
import Luxary19 from "../../assets/images/The Lux Dubai text n fonts UI-UX-19.png";
import Luxary20 from "../../assets/images/The Lux Dubai text n fonts UI-UX-20.png";
import Luxary21 from "../../assets/images/The Lux Dubai text n fonts UI-UX-21.png";
import Luxary22 from "../../assets/images/The Lux Dubai text n fonts UI-UX-22.png";
import Luxary23 from "../../assets/images/The Lux Dubai text n fonts UI-UX-23.png";
import developer from "../../assets/images/The Lux Dubai UI-UX Inner Pages pics-04.jpg";
import BannerCard from "../../components/bannerCardBox/bannerCard";
import borchue from "../../assets/images/ssir-02.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

function ResidentialOasis() {
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);

    const toggleModalPrice = () => {
    setShowModal5(!showModal5);
  };
  const toggleModalAdvice = () => {
    setShowModal6(!showModal6);
  };
  return (
    <div className="det-page-first-sec">
      <BannerCard />
      <div className="det-page-second-topText">
        <h4 className="det-page-req-text">Request Available</h4>
        <button onClick={toggleModalPrice}>UNIT & PRICES </button>
      </div>
  {showModal5 && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModalPrice}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      <div className="det-page-second-MainText">
        <span className="det-page-h1-span">RESIDENTIAL OASIS</span>{" "}
        <span className="det-page-h1-span2">WITH A PRIVATE LAGOON IN</span>
        <span className="det-page-h1-span">DUBAI SOUTH</span>
        <p className="det-page-paragraph">
          Encompassing a sprawling 136,000 m², this contemporary residential
          complex comprises 60 buildings with varying storeys, situated along an
          enchanting 18-kilometer lagoon. The crystal-clear water and pristine
          beaches evoke a coastal lifestyle, offering residents the perfect
          retreat for a refreshing dip on a hot day or a family picnic on the
          beach. The complex features its own cable car, seamlessly connecting
          residents to the Dubai metro, ensuring quick and convenient access to
          every corner of the emirate.
        </p>
      </div>
      <div className="det-page-firstImg">
        <img src={Image} />
      </div>
      <div className="second-section">
        <div class="wrapper-sec2">
          <h2 className="sec-2-title">LAYOUTS IN THE PROJECTS</h2>

          <ul class="nav nav-tabs">
            <li className="active">
              <a data-toggle="tab" href="#home">
                Studio
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#menu1">
                1 Bedroom
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#menu2">
                2 Bedroom
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#menu3">
                3 Bedroom
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div id="home" class="tab-pane fade in active">
              <div className="det-page-tabs-card">
                <div className="det-page-tab-text">
                  <h1 className="det-page-tabText-h1">
                    IN AZIZI VENICE YOU CAN CHOOSE LAYOUT STUDIOS FROM 1013
                    VARIANTS
                  </h1>
                  <h2 className="det-page-tabText-h2">FROM 130 000 $</h2>
                  <h3 className="det-page-tabText-h3-1">FROM 300 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-2">FROM</h3>
                  <h3 className="det-page-tabText-h3-2">327 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-3">1013 VARIANTS</h3>
                  <button className="det-page-tab-btn" onClick={toggleModalAdvice}>
                    GET EXPERT ADVICE
                  </button>
                </div>
                <div className="det-page-tab-image">
                  <img src={layout} style={{ width: "100%" }} />
                </div>
              </div>
            </div>

            <div id="menu1" class="tab-pane fade">
              <div className="det-page-tabs-card">
                <div className="det-page-tab-text">
                  <h1 className="det-page-tabText-h1">
                    IN AZIZI VENICE YOU CAN CHOOSE LAYOUT STUDIOS FROM 1013
                    VARIANTS
                  </h1>
                  <h2 className="det-page-tabText-h2">FROM 130 000 $</h2>
                  <h3 className="det-page-tabText-h3-1">FROM 300 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-2">FROM</h3>
                  <h3 className="det-page-tabText-h3-2">327 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-3">1013 VARIANTS</h3>
                  <button className="det-page-tab-btn" onClick={toggleModalAdvice}>
                    GET EXPERT ADVICE
                  </button>
                </div>
                <div className="det-page-tab-image">
                  <img src={layout} style={{ width: "100%" }} />
                </div>
              </div>
            </div>
            <div id="menu2" class="tab-pane fade">
              <div className="det-page-tabs-card">
                <div className="det-page-tab-text">
                  <h1 className="det-page-tabText-h1">
                    IN AZIZI VENICE YOU CAN CHOOSE LAYOUT STUDIOS FROM 1013
                    VARIANTS
                  </h1>
                  <h2 className="det-page-tabText-h2">FROM 130 000 $</h2>
                  <h3 className="det-page-tabText-h3-1">FROM 300 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-2">FROM</h3>
                  <h3 className="det-page-tabText-h3-2">327 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-3">1013 VARIANTS</h3>
                  <button className="det-page-tab-btn" onClick={toggleModalAdvice}>
                    GET EXPERT ADVICE
                  </button>
                </div>
                <div className="det-page-tab-image">
                  <img src={layout} style={{ width: "100%" }} />
                </div>
              </div>
            </div>
            <div id="menu3" class="tab-pane fade">
              <div className="det-page-tabs-card">
                <div className="det-page-tab-text">
                  <h1 className="det-page-tabText-h1">
                    IN AZIZI VENICE YOU CAN CHOOSE LAYOUT STUDIOS FROM 1013
                    VARIANTS
                  </h1>
                  <h2 className="det-page-tabText-h2">FROM 130 000 $</h2>
                  <h3 className="det-page-tabText-h3-1">FROM 300 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-2">FROM</h3>
                  <h3 className="det-page-tabText-h3-2">327 S/FT²</h3>
                  <h3 className="det-page-tabText-h3-3">1013 VARIANTS</h3>
                  <button className="det-page-tab-btn" onClick={toggleModalAdvice}>
                    GET EXPERT ADVICE
                  </button>
                </div>
                <div className="det-page-tab-image">
                  <img src={layout} style={{ width: "100%" }} />
                </div>
              </div>
            </div>
          {showModal6 && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModalAdvice}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>



      <div className="eight-section">
        <h1>LOCATIONS NEARBY</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* First column */}
              <div className="image-container text-white p-3 img-1">
                <img src={Luxary18} className="luxary-18" />
                <h1 className="innerImageText img-1">DOWN TOWN DUBAI</h1>
              </div>
            </div>
            <div className="col-md-3">
              {/* Second column */}
              <div className="image-container text-white p-3 img-2">
                <img src={Luxary19} className="luxary-19" />
                <h1 className="innerImageText img-2">DUBAI HILLS ESTATE</h1>
              </div>
            </div>
            <div className="col-md-3">
              {/* Third column with wider width */}
              <div className="image-container text-white p-3 img-3">
                <img src={Luxary20} className="luxary-20" />
                <h1 className="innerImageText img-3">EMAAR BEACH FRONT</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              {/* First column */}
              <div className="image-container text-white p-3 img-4">
                <img src={Luxary22} className="luxary-21" />
                <h1 className="innerImageText img-4">DUBAI HILLS ESTATE</h1>
              </div>
            </div>
            <div className="col-md-3">
              {/* Second column */}
              <div className="image-container text-white p-3 img-5">
                <img src={Luxary23} className="luxary-22" />
                <h1 className="innerImageText img-5">PALM JUMEIRAH</h1>
              </div>
            </div>
            <div className="col-md-6">
              {/* Third column with wider width */}
              <div className="image-container text-white p-3 img-6">
                <img src={Luxary21} className="luxary-23" />
                <h1 className="innerImageText img-6">EMAAR BEACH FRONT</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="det-page-sect-paymentPlan">
        <h1 className="det-page-Payment-text">
          PAYMENT <span className="det-page-Plan-text"> PLAN</span>
        </h1>
        <p className="det-page-Payment-middletext-p">
          We calculated the most convenient possible payment option for the
          Azizi Venice project
        </p>
        <span an className="det-page-paymentplan-bottomText-h3">PRICE FORM</span><span className="det-page-paymentplan-bottomText">$130 700</span>
        <div style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "40px"
        }}>
          <div className="det-page-box-3">
            <h1>3%</h1>
            <p>On <br></br> Booking</p>
            <p style={{
              color:"#c38b74"
            }}>$3,921</p>
          </div>
          <div className="det-page-box-width">
          </div>
          <div className="det-page-box-47">
            <h1>47%</h1>
            <p>During<br></br>Construction</p>
            <p style={{
              color:"#c38b74"
            }}>$61,429</p>
          </div>
          <div className="det-page-box-width"></div>
          <div className="det-page-box-50">
            <h1>50%</h1>
            <p>Upon completion<br></br> of construction</p>
            <p style={{
              color:"#c38b74"
            }}>$65,350</p>
          </div>
      </div>
      </div>

      <div className="Det-page-developers" > 
        <div><h1 className="det-h1-developers">DEVELOPERS</h1></div>
        <div className="det-empty-div-width"
        ></div>
        <div><img src={logo} style={{
          width:"192px"
        }}/></div>
      </div>

      <div className="det-developer-section-part-b">
        <div><img src={developer} className="det-developer-img" /></div>
        <div className="det-developer-txt">
          <p className="det-developer-txt-p">
          Azizi is a large Dubai real estate development company
          with extensive experience in the construction of residential
          complexes. Its portfolio includes 19 ready-made business
          and premium projects in Palm Jumeirah, Jebel Ali, Dubai
          Healthcare City, Al Furjan. For the Riviera in Meydan
          project, the developer received the Best Luxury Residential
          Development award. At the moment, the company has
          100 planned and under construction complexes with a
          completion date from 2021 to 2025.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResidentialOasis;
