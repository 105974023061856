import './assets/style/style.css';
import "./assets/style/responsive.css"
import Index from "./pages/Index"
import DetailPage from "./pages/Detail page/detailPage"
import DetailPageTwo from './pages/Detail Page 2/DetailPageTwo';

function App() {
  return (
    <div>
      <Index />
      <DetailPage />
      <DetailPageTwo/>

    </div>

  );
}

export default App;
