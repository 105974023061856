import React from "react";
import "./bannerCard.css";

function BannerCard() {
  return (
    <div className="banner-box-flex">
      <div className="banner-box-row1">
        <div>
          <div className="banner-card-box">Completed In <br></br> 2024<br></br><br></br> Explore More</div>
        </div>
        <div>
          <div className="banner-card-box">New Launch <br></br><br></br><br></br> Explore More</div>
        </div>
        <div>
          <div className="banner-card-box">For Invesment <br></br><br></br><br></br> Explore More</div>
        </div>
      </div>
      <div className="banner-box-row2">
        <div>
          <div className="banner-card-box">Completed In <br></br> 2024<br></br><br></br> Explore More</div>
        </div>
        <div>
          <div className="banner-card-box">New Launch <br></br><br></br><br></br> Explore More</div>
        </div>
        <div>
          <div className="banner-card-box">For Invesment <br></br><br></br><br></br> Explore More</div>
        </div>
      </div>
    </div>
  );
}

export default BannerCard;
{
  /* <div className="banner-card-box">Box1</div> */
}
