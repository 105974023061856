import React, { useState } from "react";

const SearchFilter = () => {
  // Define state variables for dropdown values
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [rating, setRating] = useState("");

  // Function to handle search button click
  const handleSearch = () => {
    // Perform search based on selected dropdown values
    console.log("Searching...");
    console.log("Category:", category);
    console.log("Location:", location);
    console.log("Price Range:", priceRange);
    console.log("Rating:", rating);
  };

  return (
    <div className="container">
      <div className="inner-box-filter">
        <div className="row">
          <div className="col-md-4">
            {/* First column */}
            <select
              style={{
                background: "none",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "90%",
                color: "white",
              }}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option style={{ color: "black" }} value="">
                All properties types
              </option>
              <option style={{ color: "black" }} value="Electronics">
                Appartments
              </option>
              <option style={{ color: "black" }} value="Clothing">
                Hotel
              </option>
              <option style={{ color: "black" }} value="Books">
                Land
              </option>
              <option style={{ color: "black" }} value="Books">
                Mansion
              </option>
              <option style={{ color: "black" }} value="Books">
                Offices
              </option>
            </select>
          </div>

          <div className="col-md-6">
            {/* Third column with wider width */}
            <input
              type="search"
              placeholder="Search property here "
              style={{
                background: "none",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "90%",
                color: "white",
              }}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            ></input>
          </div>

          <div className="col-md-2">
            <button className="filter-btn" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            {/* First column */}
            <select
              style={{
                background: "none",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "90%",
                color: "white",
              }}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option style={{ color: "black" }} value="">
                All developers
              </option>
              <option style={{ color: "black" }} value="Electronics">
                Emaar Properties for sale
              </option>
              <option style={{ color: "black" }} value="Clothing">
                DAMAC Properties for sale
              </option>
              <option style={{ color: "black" }} value="Books">
                Dubai Properties for sale
              </option>
              <option style={{ color: "black" }} value="Books">
                Aqua Properties for sale
              </option>
              <option style={{ color: "black" }} value="Books">
                Aziz Development Properties for sale
              </option>
            </select>
          </div>
          <div className="col-md-3">
            {/* Second column */}
            <select
              style={{
                background: "none",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "90%",
                color: "white",
              }}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option style={{ color: "black" }} value="">
                All Areas
              </option>
              <option style={{ color: "black" }} value="New York">
                Dubai creek Harbour
              </option>
              <option style={{ color: "black" }} value="Los Angeles">
                Wahda
              </option>
              <option style={{ color: "black" }} value="Chicago">
                Ghuwair
              </option>
            </select>
          </div>
          <div className="col-md-2">
            {/* Third column with wider width */}
            <select
              style={{
                background: "none",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "90%",
                color: "white",
              }}
              value={priceRange}
              onChange={(e) => setPriceRange(e.target.value)}
            >
              <option style={{ color: "black" }} value="">
                Bed Rooms
              </option>
              <option style={{ color: "black" }} value="$">
                1 BR
              </option>
              <option style={{ color: "black" }} value="$$">
                2 BR
              </option>
              <option style={{ color: "black" }} value="$$$">
                3 BR
              </option>
              <option style={{ color: "black" }} value="$$$">
                4 BR
              </option>
              <option style={{ color: "black" }} value="$$$">
                5 BR
              </option>
              <option style={{ color: "black" }} value="$$$">
                6 BR
              </option>
              <option style={{ color: "black" }} value="$$$">
                7 BR
              </option>
              <option style={{ color: "black" }} value="$$$">
                8 BR
              </option>
            </select>
          </div>
          <div className="col-md-2">
            {/* Third column with wider width */}
            <select
              style={{
                background: "none",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "90%",
                color: "white",
              }}
              value={priceRange}
              onChange={(e) => setPriceRange(e.target.value)}
            >
              <option style={{ color: "black" }} value="">
                Min Price
              </option>
              <option style={{ color: "black" }} value="$">
                AED 1,000,000
              </option>
              <option style={{ color: "black" }} value="$$">
                AED 1,200,000
              </option>
              <option style={{ color: "black" }} value="$$$">
                AED 1,400,000
              </option>
              <option style={{ color: "black" }} value="$$$">
                AED 1,600,000
              </option>
            </select>
          </div>
          <div className="col-md-2">
            {/* Second column */}
            <select
              style={{
                background: "none",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "90%",
                color: "white",
              }}
              value={priceRange}
              onChange={(e) => setPriceRange(e.target.value)}
            >
              <option style={{ color: "black" }} value="">
                Max Price
              </option>
              <option style={{ color: "black" }} value="$">
                AED 1,000,000
              </option>
              <option style={{ color: "black" }} value="$$">
                AED 1,200,000
              </option>
              <option style={{ color: "black" }} value="$$$">
                AED 1,400,000
              </option>
              <option style={{ color: "black" }} value="$$$">
                AED 1,600,000
              </option>
            </select>
          </div>
        </div>
      </div>

      {/*             
    <div className='inner-box-filter'>
      <select  style={{ background: 'none', border: '1px solid #ccc', borderRadius: '4px' , width: "120px", color:"white"}} value={category} onChange={(e) => setCategory(e.target.value)}>
        <option style={{color:"black"}} value="">Select Category</option>
        <option style={{color:"black"}} value="Electronics">Electronics</option>
        <option style={{color:"black"}} value="Clothing">Clothing</option>
        <option style={{color:"black"}} value="Books">Books</option>
    </select>

      <select  style={{ background: 'none', border: '1px solid #ccc', borderRadius: '4px',width:"550px", color:"white" }} value={location} onChange={(e) => setLocation(e.target.value)}>
        <option style={{color:"black"}} value="">Select Location</option>
        <option style={{color:"black"}} value="New York">New York</option>
        <option style={{color:"black"}} value="Los Angeles">Los Angeles</option>
        <option style={{color:"black"}} value="Chicago">Chicago</option>
      </select>

      <select  style={{ background: 'none', border: '1px solid #ccc', borderRadius: '4px',width: "120px",color:"white" }} value={priceRange} onChange={(e) => setPriceRange(e.target.value)}>
        <option style={{color:"black"}} value="">Select Price Range</option>
        <option style={{color:"black"}} value="$">Less than $10</option>
        <option style={{color:"black"}} value="$$">$10 - $50</option>
        <option style={{color:"black"}} value="$$$">$50 - $100</option>
      </select>

      <select  style={{ background: 'none', border: '1px solid #ccc', borderRadius: '4px',width: "120px",color:"white" }} value={rating} onChange={(e) => setRating(e.target.value)}>
        <option style={{color:"black"}} value="">Select Rating</option>
        <option style={{color:"black"}} value="1">1 Star</option>
        <option style={{color:"black"}} value="2">2 Stars</option>
        <option style={{color:"black"}} value="3">3 Stars</option>
        <option style={{color:"black"}} value="4">4 Stars</option>
        <option style={{color:"black"}} value="5">5 Stars</option>
      </select>
      <select  style={{ background: 'none', border: '1px solid #ccc', borderRadius: '4px',width:"80px",color:"white" }} value={rating} onChange={(e) => setRating(e.target.value)}>
        <option style={{color:"black"}} value="">Select Rating</option>
        <option style={{color:"black"}} value="1">1 Star</option>
        <option style={{color:"black"}} value="2">2 Stars</option>
        <option style={{color:"black"}} value="3">3 Stars</option>
        <option style={{color:"black"}} value="4">4 Stars</option>
        <option style={{color:"black"}} value="5">5 Stars</option>
      </select>
      
      <select  style={{ background: 'none', border: '1px solid #ccc',color:"white", borderRadius: '4px',width:"80px" }} value={rating} onChange={(e) => setRating(e.target.value)}>
        <option style={{color:"black"}} value="">Select Rating</option>
        <option style={{color:"black"}} value="1">1 Star</option>
        <option style={{color:"black"}} value="2">2 Stars</option>
        <option style={{color:"black"}} value="3">3 Stars</option>
        <option style={{color:"black"}} value="4">4 Stars</option>
        <option style={{color:"black"}} value="5">5 Stars</option>
      </select>

      <button className='filter-btn' onClick={handleSearch}>Search</button>
    </div> */}
    </div>
  );
};

export default SearchFilter;
