import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { faXing } from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/The Lux Dubai text n fonts UI-UX-32.png";
import startingPrice from "../../assets/images/The Lux Dubai text n fonts UI-UX-30.webp";
import paymentPlan from "../../assets/images/The Lux Dubai text n fonts UI-UX-31.webp";
import borchue from "../../assets/images/ssir-02.webp";
import { Link } from "react-router-dom";
import DetCrousel from "../../components/DetailPageOneCrousel/detCrousel";

// 
function DetailPageTwo() {
    const [isOpen, setIsOpen] = useState(false);
    const [showModal3, setShowModal3] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleModal3 = () => {
    setShowModal3(!showModal3);
  };

  return (
    <>
<div className={`containerr ${isOpen ? "sidebar-open" : ""}`}>
              
          {/* first section */}
        <div className="detaipageTwo-1-first-section">
          <div className="sideMenue">
            <div className="sideMenuBar">
              <FontAwesomeIcon
                className="humb-icon"
                icon={faBarsStaggered}
                onClick={toggleSidebar}
              />
            </div>
            <div className="logo">
              <Link to="/DetailPageTwo"><img src={logo} /></Link>
            </div>
            <div></div>
          </div>
          <div className="bannercontent">
            <div className="row-1">
              <p>NEW LAUNCH</p>
            </div>
            <div className="row-2">
              <p>An Ultra-Luxury Landmark In Palm Jumeirah</p>
            </div>
            <div className="row-3">
              <p>
                This Luxary Residence In Palm Jumeirah Will Comprise A Range Of
                Beautifully Appointed Home Including A Sellect Number Of
                Penthouse, As Well As A Range Of World-class Amenities,
                Carefully Designed To Give Every Resident The Opportunity To
                Enjoy An Eligant, Productive And Actve Lifestyle
              </p>
            </div>
            <div className="row-4">
              <div className="Start-price">
                <img src={startingPrice} />
                <p className="AED-content">AED 11,000,000</p>
                <p>STARTING PRICE</p>
              </div>

              <div className="End-price">
                <img src={paymentPlan} />
                <p className="END-content">Easy 60/40</p>
                <p>PAYMENT PLAN</p>
              </div>
            </div>
            <div className="row-5" style={{ marginRight: "0px" }}>
              <button onClick={toggleModal3}>Leave a Request</button>
            </div>
          </div>

          {showModal3 && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModal3}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* side-bar-button section */}

        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="sidebar-content">
            <div className="sideMenuBar">
              <FontAwesomeIcon
                className="humb-icon-cross"
                icon={faXing}
                onClick={toggleSidebar}
              />
            </div>

            <ul>
              <li className="fake-menu">Home</li>
              <li className="fake-menu">Contact</li>
              <li className="fake-menu">Communities</li>
                          <ul>
                              <Link to="/DetailPage"><li>Community 1</li></Link>
                              <Link to="DetailPageTwo"><li>Community 2</li></Link>
                </ul>
              <li className="fake-menu">Services</li>
              <li className="fake-menu">New Projects</li>
              <li className="fake-menu">Contact us</li>
              <li className="fake-menu">About us</li>
            </ul>
          </div>
        </div>
<div className="second-section">
          <div class="wrapper-sec2">
            <h2 className="sec-2-title">OUR<span style={{
              fontFamily: "uni-thin",
              color: "#c38b74",
              fontSize: "35px",
              fontWeight: "100",
              marginTop: "45px",
            }}> GALLERY</span> </h2>

            <ul class="nav nav-tabs">
              <li className="active">
                <a data-toggle="tab" href="#home">
                  Exterior
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#menu1">
                  Interior
                </a>
              </li>
             
              
            </ul>

            <div class="tab-content">
              <div id="home" class="tab-pane fade in active">
                <div className="cardWrap" style={{
                      width: "70%",
                  margin: "auto",
                      marginBottom:"80px"
                }}>
                  {/* <img src={CrouselImg1}/> */}
                  <DetCrousel />
                </div>
              </div>

              <div id="menu1" class="tab-pane fade">
                <div className="cardWrap" style={{
                      width: "70%",
                  margin: "auto",
                      marginBottom:"80px"
                }}>
                  {/* <img src={CrouselImg2}/> */}
                  <DetCrousel />
                </div>
              </div>
              
            </div>
          </div>
        </div>


</div>
          
    </>
  )
}

export default DetailPageTwo