import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { faXing } from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/The Lux Dubai text n fonts UI-UX-32.png";
import startingPrice from "../../assets/images/The Lux Dubai text n fonts UI-UX-30.webp";
import paymentPlan from "../../assets/images/The Lux Dubai text n fonts UI-UX-31.webp";
import borchue from "../../assets/images/ssir-02.webp";
import brouchy from "../../assets/images/manjan-01.png";
import ResidentialOasis from "./ResidentialOasis";
import Footer from "../../components/Footer";
import DetCrousel from "../../components/DetailPageOneCrousel/detCrousel";
// aminities
import townPark from "../../assets/images/Aminities/Aminities-01.png";
import pool from "../../assets/images/Aminities/Aminities-02.png";
import gym from "../../assets/images/Aminities/Aminities-03.png";
import basketball from "../../assets/images/Aminities/Aminities-04.png";
import carousel from "../../assets/images/Aminities/Aminities-05.png";
import skate from "../../assets/images/Aminities/Aminities-06.png";
// 
// aminities
import cycle from "../../assets/images/Aminities/Aminities-12.png";
import jogging from "../../assets/images/Aminities/Aminities-11.png";
import kids from "../../assets/images/Aminities/Aminities-10.png";
import train from "../../assets/images/Aminities/Aminities-09.png";
import outlets from "../../assets/images/Aminities/Aminities-07.png";
import clinic from "../../assets/images/Aminities/Aminities-08.png";
// 
// aminities
import cafe from "../../assets/images/Aminities/Aminities-13.png";
import splash from "../../assets/images/Aminities/Aminities-14.png";
import Football from "../../assets/images/Aminities/Aminities-15.png";
import tennis from "../../assets/images/Aminities/Aminities-16.png";
import pedal from "../../assets/images/Aminities/Aminities-17.png";
import tailor from "../../assets/images/Aminities/Aminities-18.png";
import { Link } from "react-router-dom";
// 

function DetailPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);

  const active = "true";
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleModal3 = () => {
    setShowModal3(!showModal3);
  };
  const toggleModalBorchure = () => {
    setShowModal4(!showModal4);
  };


  return (
    <>
      <div className={`containerr ${isOpen ? "sidebar-open" : ""}`}>
        {/* first section */}
        <div className="detaipage-1-first-section">
          <div className="sideMenue">
            <div className="sideMenuBar">
              <FontAwesomeIcon
                className="humb-icon"
                icon={faBarsStaggered}
                onClick={toggleSidebar}
              />
            </div>
            <div className="logo">
              <img src={logo} />
            </div>
            <div></div>
          </div>
          <div className="bannercontent">
            <div className="row-1">
              <p>NEW LAUNCH</p>
            </div>
            <div className="row-2">
              <p>An Ultra-Luxury Landmark In Palm Jumeirah</p>
            </div>
            <div className="row-3">
              <p>
                This Luxary Residence In Palm Jumeirah Will Comprise A Range Of
                Beautifully Appointed Home Including A Sellect Number Of
                Penthouse, As Well As A Range Of World-class Amenities,
                Carefully Designed To Give Every Resident The Opportunity To
                Enjoy An Eligant, Productive And Actve Lifestyle
              </p>
            </div>
            <div className="row-4">
              <div className="Start-price">
                <img src={startingPrice} />
                <p className="AED-content">AED 11,000,000</p>
                <p>STARTING PRICE</p>
              </div>

              <div className="End-price">
                <img src={paymentPlan} />
                <p className="END-content">Easy 60/40</p>
                <p>PAYMENT PLAN</p>
              </div>
            </div>
            <div className="row-5" style={{ marginRight: "0px" }}>
              <button onClick={toggleModal3}>Leave a Request</button>
            </div>
          </div>

          {showModal3 && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModal3}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* side-bar-button section */}

        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="sidebar-content">
            <div className="sideMenuBar">
              <FontAwesomeIcon
                className="humb-icon-cross"
                icon={faXing}
                onClick={toggleSidebar}
              />
            </div>

            <ul>
              <li className="fake-menu">Home</li>
              <li className="fake-menu">Contact</li>
              <li className="fake-menu">Communities</li>
                  <ul>
                              <Link to="/DetailPage"><li>Community 1</li></Link>
                              <Link to="DetailPageTwo"><li>Community 2</li></Link>
                </ul>
              <li className="fake-menu">Services</li>
              <li className="fake-menu">New Projects</li>
              <li className="fake-menu">Contact us</li>
              <li className="fake-menu">About us</li>
            </ul>
          </div>
        </div>

        
        <ResidentialOasis />

        <div className="det-page-download-brochure-section"> 
          <div className="det-page-brochure-content">
            <h1 className="det-page-brouchre-h1">DOWNLOAD THE<span className="det-page-brouchre-span"> AZIZI VENICE PROJECT</span> BROCHURE</h1>
            <p className="det-page-brouchre-p">The magazine contains new projects, appartments, villas and townhouses in new building in dubai at a special price, ideas for arrangement and special advice.</p>

            <img src={brouchy} className="det-page-brouchre-img" style={{
              width:"65%",
            }} />
            <div>
              <button className="det-page-brouchre-btn">DOWNLOAD BROCHURE <FontAwesomeIcon
                            icon={faArrowUp}
                className="det-page-brochure-arrow"
                onClick={toggleModalBorchure}
                          /> </button>

            </div>
             {showModal3 && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModal3}>
                  &times;
                </span>

                <div className="modal-display-flex">
                  <div className="catalog-modal-image">
                    {" "}
                    <img src={borchue} className="modal-image" />
                  </div>
                  <div className="catalog-modal-content">
                    <h1 className="catalog-modal-heading">
                      SUBMIT YOUR DETAILS TO GET FREE PDF BROCHURE
                    </h1>
                    <div className="catalog-modal-form">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="modal-input-txt"
                      />{" "}
                      <br></br>
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="modal-input-email"
                      />{" "}
                      <br></br>
                      <input
                        type="number"
                        placeholder="Your Number"
                        className="modal-input-num"
                      />{" "}
                      <br></br>
                      <div>
                        <button className="modal-submit-btn">
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="footer-button-arrow"
                          />
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}  
          </div>

        </div>

        <div className="det-page-community-facility">
          <div className="container">
            <div className="de-row-one" style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap:"wrap"
            }}>
              <div>
                <div className="cm-a" style={{
                    margin: "20px 0px",
              }}>
                <img src={townPark} style={{
                  width: "100px",
                  margin: "8px 19px",
                  }}/><br></br>
                  <span>Town square park</span>
              </div>
              <div className="cm-b" style={{
                    margin: "20px 0px",
              }}>
                <img src={pool} style={{
                  width: "100px",
                  margin: "8px 8px",
                  }}/><br></br>
                  <span>Swimming Pool</span>
              </div>
              <div className="cm-c" style={{
                    margin: "20px 0px",
              }}>
                <img src={gym} style={{
                  width: "100px",
                  margin: "8px -33px",
                  }}/><br></br>
                  <span>Gym</span>
              </div>
            </div>
            <div>
              <div className="cm-d" style={{
                  margin: "20px 0px",
                    marginTop: "36px",

              }}>
                <img src={basketball} style={{
                  width: "100px",
                  margin: "8px 8px",
                  }}/><br></br>
                  <span>BasketBall courts</span>
              </div>
              <div className="cm-e" style={{
                    margin: "20px 0px",
              }}>
                <img src={carousel} style={{
                  width: "100px",
                  
                  }}/><br></br>
                  <span>Carousel</span>
              </div>
              <div className="cm-af">
                <img src={skate} style={{
                  width: "100px",
                  }}/><br></br>
                  <span>Skate Park</span>
              </div>
            </div>
              <div>
                <div className="cm-a">
                <img src={cycle} style={{
                  width: "100px",
                  marginTop: "15px",
                  }}/><br></br>
                  <span>Cycling Track</span>
              </div>
              <div className="cm-b">
                <img src={jogging} style={{
                  width: "85px",
                  margin: "8px 19px",
                  }}/><br></br>
                  <span>Jogging Track</span>
              </div>
              <div className="cm-c">
                <img src={kids} style={{
                  width: "100px",
                  margin: "8px 19px",
                  }}/><br></br>
                  <span>Kids Play Area</span>
              </div>
            </div>
            <div>
              <div className="cm-d">
                <img src={train} style={{
                  width: "100px",
                  marginTop: "35px",
                  }}/><br></br>
                  <span>Kids Train</span>
              </div>
              <div className="cm-e">
                <img src={outlets} style={{
                  width: "100px",
                  marginTop: "15px",
                  }}/><br></br>
                  <span>Retail outlets</span>
              </div>
              <div className="cm-af">
                <img src={clinic} style={{
                  width: "100px",
                  margin: "8px 19px",
                  }}/><br></br>
                  <span>Veterinary Clinic</span>
              </div>
              </div>
              <div>
                <div className="cm-a" style={{
                marginTop:"32px",
              }}>
                <img src={cafe} style={{
                  width: "100px",
                  }}/><br></br>
                  <span>Restaurants & cafe </span>
              </div>
              <div className="cm-b" style={{
                marginTop:"0px",
              }}>
                <img src={splash} style={{
                  width: "100px",
                  margin: "8px 19px",
                  }}/><br></br>
                  <span>Splash Pad</span>
              </div>
              <div className="cm-c" style={{
                marginTop:"13px",
              }}>
                <img src={Football} style={{
                  width: "100px",
                  margin: "8px 19px",
                  }}/><br></br>
                  <span>Football Pitch</span>
              </div>
              </div>          
            <div>
              <div className="cm-d">
                <img src={tennis} style={{
                  width: "100px",
                  marginTop:"34px"
                  }}/><br></br>
                  <span>Squash & Tennis</span>
              </div>
                <div className="cm-e" style={{
                marginTop:"16px",
              }}>
                <img src={pedal} style={{
                  width: "100px",
                  marginTop:"5px",
                  }}/><br></br>
                  <span>Padel Court</span>
              </div>
              <div className="cm-af" style={{
                marginTop:"26px",
              }}>
                <img src={tailor} style={{
                  width: "100px",
                  }}/><br></br>
                  <span>Tailor Shop</span>
              </div>
            </div>
          </div>
          </div>
</div>

        {/* map */}
        <div className="seventh-section">
          <h1>EXPLORE THE MAP</h1>
          {/* <img src={map} className='map'/> */}
          <iframe
            style={{ width: "100%", height: "80vh" }}
            src="https://b2b.behomes.tech/frame/map/1702729359763x367954987423891460?lang=EN"
          ></iframe>
        </div>

        {/* crousel */}
        <div className="second-section">
          <div class="wrapper-sec2">
            <h2 className="sec-2-title">OUR<span style={{
              fontFamily: "uni-thin",
              color: "#c38b74",
              fontSize: "35px",
              fontWeight: "100",
              marginTop: "45px",
            }}> GALLERY</span> </h2>

            <ul class="nav nav-tabs">
              <li className="active">
                <a data-toggle="tab" href="#home">
                  Exterior
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#menu1">
                  Interior
                </a>
              </li>
             
              
            </ul>

            <div class="tab-content">
              <div id="home" class="tab-pane fade in active">
                <div className="cardWrap" style={{
                      width: "70%",
                  margin: "auto",
                      marginBottom:"80px"
                }}>
                  {/* <img src={CrouselImg1}/> */}
                  <DetCrousel />
                </div>
              </div>

              <div id="menu1" class="tab-pane fade">
                <div className="cardWrap" style={{
                      width: "70%",
                  margin: "auto",
                      marginBottom:"80px"
                }}>
                  {/* <img src={CrouselImg2}/> */}
                  <DetCrousel />
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default DetailPage;
